import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import AnalyticsService from '../../services/AnalyticsService';

import Section from '../layout/Section';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Typo from '../../common/components/Typo'
import Meta from '../layout/Meta';

import Layout from '../layout/Layout';
import Button from '../../common/components/Button';


const NuovavitaMembership = ({layout}) => {
	window.location.replace("https://www.nuova-vita.it/area-soci");
  let { lang } = useParams();

  useEffect(() => {
    AnalyticsService.init();  
  }, []);


  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Tesseramento" : "Membership"}
        languages={{"it": "muova-vita/membership"}}
      />
      <Section variant="primary" image={"/photos/headers/nuovavita-tesseramento.jpg"}>
        <Row>
          <Col widthmd={12}>  
            <Typo type="h0" align="center" color="#ffffff">Nuova Vita</Typo>
            <Typo type="h1" align="center" color="#ffffff">{lang === "it" ? "Area Soci" : "Membership"}</Typo>
            <Typo type="p" align="center" color="#ffffff">
              La quota associativa è obbligatoria per usufruire dei servizi e partecipare all'attività dell'ASD.<br/>
              Diventando socio, avrai diritto alla tessera dell'ASD, che include l’assicurazione fornita da ASI, ente di promozione sportiva riconosciuto dal CONI. Grazie alla tessera, potrai accedere ai servizi offerti da tutte le ASD affiliate ad ASI.
            </Typo>          
          </Col>
        </Row>
      </Section>
      
      <Section variant="white">
        <Row>
          <Col widthmd={12}>  
            <Typo type="h2" align="center">Iscrizione per l'anno {new Date().getFullYear()}</Typo>
            <Typo type="p" align="left">
              L'iscrizione per il {new Date().getFullYear()} ha un costo annuale di €50 ed ha validità dal momento dell'attivazione fino al 31/12/{new Date().getFullYear()}.<br/>
              Per completare l’iscrizione come socio, effettua il pagamento tramite bonifico e compila il modulo cliccando sul pulsante qui sotto.<br/><br/>
              Estremi per il pagamento:
              <ul>
                <li><b>IBAN</b>: IT50V0103057460000010294285</li>
                <li><b>Intestato a</b>: Nuova Vita ASD</li>
                <li><b>Causale</b>: Quota Associativa {new Date().getFullYear()} (nome e cognome dell'associato)</li>
                <li><b>Importo</b>: €50</li>
              </ul>
              <center><a href={"https://www.wespoort.it/modulo-iscrizione-societa?society=d3174689-a425-44c5-b669-04133e03a951"} target="_blank" rel="noreferrer"><Button>Modulo Iscrizione e Rinnovo</Button></a></center>
            </Typo>
          </Col>
        </Row>
      </Section>

      {/* <Section variant="light">
        <Row>
          <Col widthmd={12}>  
            <Typo type="h0" align="center">Per rimanere sempre aggiornato</Typo>
            <Typo type="h2" align="center">Scarica l'app</Typo>
            <Typo type="p" align="center">asdasdasd</Typo>
            <center><a href={"#"} target="_blank" rel="noreferrer"><Button>Play Store</Button></a> <a href={"#"} target="_blank" rel="noreferrer"><Button>Apple Store</Button></a></center>
          </Col>
        </Row>
      </Section> */}
      
    </Layout>
  )
}

export default NuovavitaMembership;
