import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';


import AnalyticsService from '../../services/AnalyticsService';



import Section from '../layout/Section';
import Box from '../layout/Box';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Typo from '../../common/components/Typo'
import Meta from '../layout/Meta';

import Layout from '../layout/Layout';


const NuovavitaHorselife = ({layout}) => {
	window.location.replace("https://www.nuova-vita.it/pensione-cavalli/vita-da-cavallo");

  let { lang } = useParams();
  

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Vita da Cavallo - Nuova Vita ASD" : "xxx"}
        languages={{"it": "nuova-vita/boarding"}}
      />
      <Section variant="primary" image={"/photos/headers/nuovavita-horselife.jpg"}>
        <Row>
          <Col widthmd={12}>  
            <Typo type="h0" align="center" color="#ffffff">Nuova Vita ASD</Typo>
            <Typo type="h1" align="center" color="#ffffff">{lang === "it" ? "Vita da Cavallo" : "xxx"}</Typo>
            <Typo type="p" color="#ffffff">I nostri cavalli vivono in branco, con accesso continuo a fieno e acqua pulita, arricchimenti ambientali, e spazi studiati per favorire il movimento e il benessere. Ogni dettaglio è pensato per rispettare la loro natura e le esigenze individuali.</Typo>
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <Row>
          <Col widthmd={8}>
            <Typo type="h3">Nutrire in modo naturale</Typo>
            <Typo type="p">
              Offriamo ai cavalli un’alimentazione che rispecchia il loro istinto naturale. Il fieno è sempre disponibile in reti slow-feeding, favorendo un consumo lento e regolare, utile per la digestione e per prevenire comportamenti ansiosi legati al cibo. I punti fieno sono distribuiti in modo strategico, garantendo accesso equo a tutti i cavalli senza competizioni. Inoltre, i nostri ospiti possono accedere al pascolo erboso in base alle loro necessità e possibilità. Blocchi di sali minerali di diverse tipologie sono sempre a disposizione, insieme a integrazioni mirate fornite in base alle specifiche esigenze di ogni cavallo.
            </Typo>
        
            <hr/>

            <Typo type="h3">Idratazione ottimale</Typo>
            <Typo type="p">
              L'acqua fresca e pulita è sempre disponibile. Durante l’estate, abbiamo implementato soluzioni per un’idratazione ottimale, come il <i>buffet d'acqua</i>, che offre secchi con elettroliti o sali minerali, e l’opzione di immergere gli zoccoli durante l’abbeverata, garantendo elasticità e salute degli zoccoli.
            </Typo>
            
            <hr/>

            <Typo type="h3">Comfort e protezione</Typo>
            <Typo type="p">
              I cavalli possono scegliere liberamente tra ombra naturale offerta dagli alberi o ampi ripari, progettati per accogliere più cavalli contemporaneamente. I ripari non solo offrono protezione dalle intemperie, ma permettono anche di consumare fieno all’asciutto durante le giornate piovose, assicurando un ambiente confortevole e sereno in ogni stagione.
            </Typo>
            
            <hr/>

            <Typo type="h3">Una vita di branco</Typo>
            <Typo type="p">
              Crediamo nel potere del branco per il benessere psicologico del cavallo. I nuovi arrivati iniziano la loro esperienza in un paddock di ambientamento, dove possono adattarsi gradualmente al nostro ambiente e conoscere i compagni. Per facilitare l’inserimento, utilizziamo cavalli particolarmente socievoli e accoglienti, introducendo il nuovo membro prima in un piccolo gruppo e poi nell’intero branco. Il nostro branco è stabile e consolidato, creando un ambiente sereno e privo di stress.
            </Typo>
            
            <hr/>

            <Typo type="h3">Movimento e stimolazione</Typo>
            <Typo type="p">
              I nostri 3 ettari di terreno sono progettati per stimolare i cavalli sia fisicamente che mentalmente. Gli arricchimenti ambientali includono grattatoi, zone di sabbia per rotolarsi, sali/scendi naturali e corridoi per favorire il movimento. Piccole reti di fieno, ciuffi d’erba e risorse nascoste stimolano la curiosità e l’attività quotidiana dei cavalli, contribuendo al loro equilibrio psicologico.
            </Typo>
            

          </Col>
          <Col widthmd={4}>
            <Box variant="primary">
              <Typo type="h3" color="#ffffff" align="center">Cura e attenzione individuale</Typo>
              <Typo type="p" color="#ffffff">
                Ogni cavallo è unico, e la nostra gestione riflette questa convinzione. Offriamo soluzioni specifiche per cavalli con esigenze particolari:<br/><br/>

                <b>Cavalli con problemi fisici</b><br/>
                Box e paddock adatti per cavalli zoppi, atassici o con patologie respiratorie.<br/><br/>

                <b>Cavalli in riabilitazione</b><br/>
                Programmi personalizzati per cavalli in fase di recupero fisico o comportamentale.<br/><br/>

                <b>Alimentazione personalizzata</b><br/>
                Regimi alimentari studiati per cavalli sovrappeso o sottopeso. Garantiamo a tutti i cavalli un supporto costante e la possibilità di socializzare e vivere momenti sereni, rispettando sia le loro esigenze fisiche che psicologiche.
              </Typo> 
            </Box>        

          </Col>
        </Row>
      </Section>
      
    </Layout>
  )
}

export default NuovavitaHorselife;
